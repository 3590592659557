const langmap = new Proxy(
  {
    ach: {
      nativeName: 'Lwo',
      englishName: 'Acholi',
    },
    ady: {
      nativeName: 'Адыгэбзэ',
      englishName: 'Adyghe',
    },
    af: {
      nativeName: 'Afrikaans',
      englishName: 'Afrikaans',
    },
    'af-NA': {
      nativeName: 'Afrikaans (Namibia)',
      englishName: 'Afrikaans (Namibia)',
    },
    'af-ZA': {
      nativeName: 'Afrikaans (South Africa)',
      englishName: 'Afrikaans (South Africa)',
    },
    ak: {
      nativeName: 'Tɕɥi',
      englishName: 'Akan',
    },
    ar: {
      nativeName: 'العربية',
      englishName: 'Arabic',
    },
    'ar-AR': {
      nativeName: 'العربية',
      englishName: 'Arabic',
    },
    'ar-MA': {
      nativeName: 'العربية',
      englishName: 'Arabic (Morocco)',
    },
    'ar-SA': {
      nativeName: 'العربية (السعودية)',
      englishName: 'Arabic (Saudi Arabia)',
    },
    'ay-BO': {
      nativeName: 'Aymar aru',
      englishName: 'Aymara',
    },
    az: {
      nativeName: 'Azərbaycan dili',
      englishName: 'Azerbaijani',
    },
    'az-AZ': {
      nativeName: 'Azərbaycan dili',
      englishName: 'Azerbaijani',
    },
    'be-BY': {
      nativeName: 'Беларуская',
      englishName: 'Belarusian',
    },
    bg: {
      nativeName: 'Български',
      englishName: 'Bulgarian',
    },
    'bg-BG': {
      nativeName: 'Български',
      englishName: 'Bulgarian',
    },
    bn: {
      nativeName: 'বাংলা',
      englishName: 'Bengali',
    },
    'bn-IN': {
      nativeName: 'বাংলা (ভারত)',
      englishName: 'Bengali (India)',
    },
    'bn-BD': {
      nativeName: 'বাংলা(বাংলাদেশ)',
      englishName: 'Bengali (Bangladesh)',
    },
    br: {
      nativeName: 'Brezhoneg',
      englishName: 'Breton',
    },
    bs: {
      nativeName: 'Bosanski',
      englishName: 'Bosnian',
    },
    'bs-BA': {
      nativeName: 'Bosanski',
      englishName: 'Bosnian',
    },
    ca: {
      nativeName: 'Català',
      englishName: 'Catalan',
    },
    'ca-ES': {
      nativeName: 'Català',
      englishName: 'Catalan',
    },
    cak: {
      nativeName: 'Maya Kaqchikel',
      englishName: 'Kaqchikel',
    },
    'ck-US': {
      nativeName: 'ᏣᎳᎩ (tsalagi)',
      englishName: 'Cherokee',
    },
    cs: {
      nativeName: 'Čeština',
      englishName: 'Czech',
    },
    'cs-CZ': {
      nativeName: 'Čeština',
      englishName: 'Czech',
    },
    cy: {
      nativeName: 'Cymraeg',
      englishName: 'Welsh',
    },
    'cy-GB': {
      nativeName: 'Cymraeg',
      englishName: 'Welsh',
    },
    da: {
      nativeName: 'Dansk',
      englishName: 'Danish',
    },
    'da-DK': {
      nativeName: 'Dansk',
      englishName: 'Danish',
    },
    de: {
      nativeName: 'Deutsch',
      englishName: 'German',
    },
    'de-AT': {
      nativeName: 'Deutsch (Österreich)',
      englishName: 'German (Austria)',
    },
    'de-DE': {
      nativeName: 'Deutsch (Deutschland)',
      englishName: 'German (Germany)',
    },
    'de-CH': {
      nativeName: 'Deutsch (Schweiz)',
      englishName: 'German (Switzerland)',
    },
    dsb: {
      nativeName: 'Dolnoserbšćina',
      englishName: 'Lower Sorbian',
    },
    el: {
      nativeName: 'Ελληνικά',
      englishName: 'Greek',
    },
    'el-GR': {
      nativeName: 'Ελληνικά',
      englishName: 'Greek (Greece)',
    },
    en: {
      nativeName: 'English',
      englishName: 'English',
    },
    'en-GB': {
      nativeName: 'English (UK)',
      englishName: 'English (UK)',
    },
    'en-AU': {
      nativeName: 'English (Australia)',
      englishName: 'English (Australia)',
    },
    'en-CA': {
      nativeName: 'English (Canada)',
      englishName: 'English (Canada)',
    },
    'en-IE': {
      nativeName: 'English (Ireland)',
      englishName: 'English (Ireland)',
    },
    'en-IN': {
      nativeName: 'English (India)',
      englishName: 'English (India)',
    },
    'en-PI': {
      nativeName: 'English (Pirate)',
      englishName: 'English (Pirate)',
    },
    'en-UD': {
      nativeName: 'English (Upside Down)',
      englishName: 'English (Upside Down)',
    },
    'en-US': {
      nativeName: 'English (US)',
      englishName: 'English (US)',
    },
    'en-ZA': {
      nativeName: 'English (South Africa)',
      englishName: 'English (South Africa)',
    },
    'en@pirate': {
      nativeName: 'English (Pirate)',
      englishName: 'English (Pirate)',
    },
    eo: {
      nativeName: 'Esperanto',
      englishName: 'Esperanto',
    },
    'eo-EO': {
      nativeName: 'Esperanto',
      englishName: 'Esperanto',
    },
    es: {
      nativeName: 'Español',
      englishName: 'Spanish',
    },
    'es-AR': {
      nativeName: 'Español (Argentine)',
      englishName: 'Spanish (Argentina)',
    },
    'es-419': {
      nativeName: 'Español (Latinoamérica)',
      englishName: 'Spanish (Latin America)',
    },
    'es-CL': {
      nativeName: 'Español (Chile)',
      englishName: 'Spanish (Chile)',
    },
    'es-CO': {
      nativeName: 'Español (Colombia)',
      englishName: 'Spanish (Colombia)',
    },
    'es-EC': {
      nativeName: 'Español (Ecuador)',
      englishName: 'Spanish (Ecuador)',
    },
    'es-ES': {
      nativeName: 'Español (España)',
      englishName: 'Spanish (Spain)',
    },
    'es-LA': {
      nativeName: 'Español (Latinoamérica)',
      englishName: 'Spanish (Latin America)',
    },
    'es-NI': {
      nativeName: 'Español (Nicaragua)',
      englishName: 'Spanish (Nicaragua)',
    },
    'es-MX': {
      nativeName: 'Español (México)',
      englishName: 'Spanish (Mexico)',
    },
    'es-US': {
      nativeName: 'Español (Estados Unidos)',
      englishName: 'Spanish (United States)',
    },
    'es-VE': {
      nativeName: 'Español (Venezuela)',
      englishName: 'Spanish (Venezuela)',
    },
    et: {
      nativeName: 'eesti keel',
      englishName: 'Estonian',
    },
    'et-EE': {
      nativeName: 'Eesti (Estonia)',
      englishName: 'Estonian (Estonia)',
    },
    eu: {
      nativeName: 'Euskara',
      englishName: 'Basque',
    },
    'eu-ES': {
      nativeName: 'Euskara',
      englishName: 'Basque',
    },
    fa: {
      nativeName: 'فارسی',
      englishName: 'Persian',
    },
    'fa-IR': {
      nativeName: 'فارسی',
      englishName: 'Persian',
    },
    'fb-LT': {
      nativeName: 'Leet Speak',
      englishName: 'Leet',
    },
    ff: {
      nativeName: 'Fulah',
      englishName: 'Fulah',
    },
    fi: {
      nativeName: 'Suomi',
      englishName: 'Finnish',
    },
    'fi-FI': {
      nativeName: 'Suomi',
      englishName: 'Finnish',
    },
    'fo-FO': {
      nativeName: 'Føroyskt',
      englishName: 'Faroese',
    },
    fr: {
      nativeName: 'Français',
      englishName: 'French',
    },
    'fr-CA': {
      nativeName: 'Français (Canada)',
      englishName: 'French (Canada)',
    },
    'fr-FR': {
      nativeName: 'Français (France)',
      englishName: 'French (France)',
    },
    'fr-BE': {
      nativeName: 'Français (Belgique)',
      englishName: 'French (Belgium)',
    },
    'fr-CH': {
      nativeName: 'Français (Suisse)',
      englishName: 'French (Switzerland)',
    },
    'fy-NL': {
      nativeName: 'Frysk',
      englishName: 'Frisian (West)',
    },
    ga: {
      nativeName: 'Gaeilge',
      englishName: 'Irish',
    },
    'ga-IE': {
      nativeName: 'Gaeilge',
      englishName: 'Irish',
    },
    gd: {
      nativeName: 'Gàidhlig',
      englishName: 'Gaelic',
    },
    gl: {
      nativeName: 'Galego',
      englishName: 'Galician',
    },
    'gl-ES': {
      nativeName: 'Galego',
      englishName: 'Galician',
    },
    'gn-PY': {
      nativeName: "Avañe'ẽ",
      englishName: 'Guarani',
    },
    'gu-IN': {
      nativeName: 'ગુજરાતી',
      englishName: 'Gujarati',
    },
    gv: {
      nativeName: 'Gaelg',
      englishName: 'Manx',
    },
    'gx-GR': {
      nativeName: 'Ἑλληνική ἀρχαία',
      englishName: 'Classical Greek',
    },
    he: {
      nativeName: 'עברית‏',
      englishName: 'Hebrew',
    },
    'he-IL': {
      nativeName: 'עברית‏',
      englishName: 'Hebrew',
    },
    hi: {
      nativeName: 'हिन्दी',
      englishName: 'Hindi',
    },
    'hi-IN': {
      nativeName: 'हिन्दी',
      englishName: 'Hindi',
    },
    hr: {
      nativeName: 'Hrvatski',
      englishName: 'Croatian',
    },
    'hr-HR': {
      nativeName: 'Hrvatski',
      englishName: 'Croatian',
    },
    hsb: {
      nativeName: 'Hornjoserbšćina',
      englishName: 'Upper Sorbian',
    },
    ht: {
      nativeName: 'Kreyòl',
      englishName: 'Haitian Creole',
    },
    hu: {
      nativeName: 'Magyar',
      englishName: 'Hungarian',
    },
    'hu-HU': {
      nativeName: 'Magyar',
      englishName: 'Hungarian',
    },
    'hy-AM': {
      nativeName: 'Հայերեն',
      englishName: 'Armenian',
    },
    id: {
      nativeName: 'Bahasa Indonesia',
      englishName: 'Indonesian',
    },
    'id-ID': {
      nativeName: 'Bahasa Indonesia',
      englishName: 'Indonesian',
    },
    is: {
      nativeName: 'Íslenska',
      englishName: 'Icelandic',
    },
    'is-IS': {
      nativeName: 'Íslenska (Iceland)',
      englishName: 'Icelandic (Iceland)',
    },
    it: {
      nativeName: 'Italiano',
      englishName: 'Italian',
    },
    'it-IT': {
      nativeName: 'Italiano',
      englishName: 'Italian',
    },
    ja: {
      nativeName: '日本語',
      englishName: 'Japanese',
    },
    'ja-JP': {
      nativeName: '日本語 (日本)',
      englishName: 'Japanese (Japan)',
    },
    'jv-ID': {
      nativeName: 'Basa Jawa',
      englishName: 'Javanese',
    },
    'ka-GE': {
      nativeName: 'ქართული',
      englishName: 'Georgian',
    },
    'kk-KZ': {
      nativeName: 'Қазақша',
      englishName: 'Kazakh',
    },
    km: {
      nativeName: 'ភាសាខ្មែរ',
      englishName: 'Khmer',
    },
    'km-KH': {
      nativeName: 'ភាសាខ្មែរ',
      englishName: 'Khmer',
    },
    kab: {
      nativeName: 'Taqbaylit',
      englishName: 'Kabyle',
    },
    kn: {
      nativeName: 'ಕನ್ನಡ',
      englishName: 'Kannada',
    },
    'kn-IN': {
      nativeName: 'ಕನ್ನಡ (India)',
      englishName: 'Kannada (India)',
    },
    ko: {
      nativeName: '한국어',
      englishName: 'Korean',
    },
    'ko-KR': {
      nativeName: '한국어 (한국)',
      englishName: 'Korean (Korea)',
    },
    'ku-TR': {
      nativeName: 'Kurdî',
      englishName: 'Kurdish',
    },
    kw: {
      nativeName: 'Kernewek',
      englishName: 'Cornish',
    },
    la: {
      nativeName: 'Latin',
      englishName: 'Latin',
    },
    'la-VA': {
      nativeName: 'Latin',
      englishName: 'Latin',
    },
    lb: {
      nativeName: 'Lëtzebuergesch',
      englishName: 'Luxembourgish',
    },
    'li-NL': {
      nativeName: 'Lèmbörgs',
      englishName: 'Limburgish',
    },
    lt: {
      nativeName: 'Lietuvių',
      englishName: 'Lithuanian',
    },
    'lt-LT': {
      nativeName: 'Lietuvių',
      englishName: 'Lithuanian',
    },
    lv: {
      nativeName: 'Latviešu',
      englishName: 'Latvian',
    },
    'lv-LV': {
      nativeName: 'Latviešu',
      englishName: 'Latvian',
    },
    mai: {
      nativeName: 'मैथिली, মৈথিলী',
      englishName: 'Maithili',
    },
    'mg-MG': {
      nativeName: 'Malagasy',
      englishName: 'Malagasy',
    },
    mk: {
      nativeName: 'Македонски',
      englishName: 'Macedonian',
    },
    'mk-MK': {
      nativeName: 'Македонски (Македонски)',
      englishName: 'Macedonian (Macedonian)',
    },
    ml: {
      nativeName: 'മലയാളം',
      englishName: 'Malayalam',
    },
    'ml-IN': {
      nativeName: 'മലയാളം',
      englishName: 'Malayalam',
    },
    'mn-MN': {
      nativeName: 'Монгол',
      englishName: 'Mongolian',
    },
    mr: {
      nativeName: 'मराठी',
      englishName: 'Marathi',
    },
    'mr-IN': {
      nativeName: 'मराठी',
      englishName: 'Marathi',
    },
    ms: {
      nativeName: 'Bahasa Melayu',
      englishName: 'Malay',
    },
    'ms-MY': {
      nativeName: 'Bahasa Melayu',
      englishName: 'Malay',
    },
    mt: {
      nativeName: 'Malti',
      englishName: 'Maltese',
    },
    'mt-MT': {
      nativeName: 'Malti',
      englishName: 'Maltese',
    },
    my: {
      nativeName: 'ဗမာစကာ',
      englishName: 'Burmese',
    },
    no: {
      nativeName: 'Norsk',
      englishName: 'Norwegian',
    },
    nb: {
      nativeName: 'Norsk (bokmål)',
      englishName: 'Norwegian (bokmal)',
    },
    'nb-NO': {
      nativeName: 'Norsk (bokmål)',
      englishName: 'Norwegian (bokmal)',
    },
    ne: {
      nativeName: 'नेपाली',
      englishName: 'Nepali',
    },
    'ne-NP': {
      nativeName: 'नेपाली',
      englishName: 'Nepali',
    },
    nl: {
      nativeName: 'Nederlands',
      englishName: 'Dutch',
    },
    'nl-BE': {
      nativeName: 'Nederlands (België)',
      englishName: 'Dutch (Belgium)',
    },
    'nl-NL': {
      nativeName: 'Nederlands (Nederland)',
      englishName: 'Dutch (Netherlands)',
    },
    'nn-NO': {
      nativeName: 'Norsk (nynorsk)',
      englishName: 'Norwegian (nynorsk)',
    },
    oc: {
      nativeName: 'Occitan',
      englishName: 'Occitan',
    },
    'or-IN': {
      nativeName: 'ଓଡ଼ିଆ',
      englishName: 'Oriya',
    },
    pa: {
      nativeName: 'ਪੰਜਾਬੀ',
      englishName: 'Punjabi',
    },
    'pa-IN': {
      nativeName: 'ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)',
      englishName: 'Punjabi (India)',
    },
    pl: {
      nativeName: 'Polski',
      englishName: 'Polish',
    },
    'pl-PL': {
      nativeName: 'Polski',
      englishName: 'Polish',
    },
    'ps-AF': {
      nativeName: 'پښتو',
      englishName: 'Pashto',
    },
    pt: {
      nativeName: 'Português',
      englishName: 'Portuguese',
    },
    'pt-BR': {
      nativeName: 'Português (Brasil)',
      englishName: 'Portuguese (Brazil)',
    },
    'pt-PT': {
      nativeName: 'Português (Portugal)',
      englishName: 'Portuguese (Portugal)',
    },
    'qu-PE': {
      nativeName: 'Qhichwa',
      englishName: 'Quechua',
    },
    'rm-CH': {
      nativeName: 'Rumantsch',
      englishName: 'Romansh',
    },
    ro: {
      nativeName: 'Română',
      englishName: 'Romanian',
    },
    'ro-RO': {
      nativeName: 'Română',
      englishName: 'Romanian',
    },
    ru: {
      nativeName: 'Русский',
      englishName: 'Russian',
    },
    'ru-RU': {
      nativeName: 'Русский',
      englishName: 'Russian',
    },
    'sa-IN': {
      nativeName: 'संस्कृतम्',
      englishName: 'Sanskrit',
    },
    'se-NO': {
      nativeName: 'Davvisámegiella',
      englishName: 'Northern Sámi',
    },
    sh: {
      nativeName: 'српскохрватски',
      englishName: 'Serbo-Croatian',
    },
    'si-LK': {
      nativeName: 'පළාත',
      englishName: 'Sinhala (Sri Lanka)',
    },
    sk: {
      nativeName: 'Slovenčina',
      englishName: 'Slovak',
    },
    'sk-SK': {
      nativeName: 'Slovenčina (Slovakia)',
      englishName: 'Slovak (Slovakia)',
    },
    sl: {
      nativeName: 'Slovenščina',
      englishName: 'Slovenian',
    },
    'sl-SI': {
      nativeName: 'Slovenščina',
      englishName: 'Slovenian',
    },
    'so-SO': {
      nativeName: 'Soomaaliga',
      englishName: 'Somali',
    },
    sq: {
      nativeName: 'Shqip',
      englishName: 'Albanian',
    },
    'sq-AL': {
      nativeName: 'Shqip',
      englishName: 'Albanian',
    },
    sr: {
      nativeName: 'Српски',
      englishName: 'Serbian',
    },
    'sr-RS': {
      nativeName: 'Српски (Serbia)',
      englishName: 'Serbian (Serbia)',
    },
    su: {
      nativeName: 'Basa Sunda',
      englishName: 'Sundanese',
    },
    sv: {
      nativeName: 'Svenska',
      englishName: 'Swedish',
    },
    'sv-SE': {
      nativeName: 'Svenska',
      englishName: 'Swedish',
    },
    sw: {
      nativeName: 'Kiswahili',
      englishName: 'Swahili',
    },
    'sw-KE': {
      nativeName: 'Kiswahili',
      englishName: 'Swahili (Kenya)',
    },
    ta: {
      nativeName: 'தமிழ்',
      englishName: 'Tamil',
    },
    'ta-IN': {
      nativeName: 'தமிழ்',
      englishName: 'Tamil',
    },
    te: {
      nativeName: 'తెలుగు',
      englishName: 'Telugu',
    },
    'te-IN': {
      nativeName: 'తెలుగు',
      englishName: 'Telugu',
    },
    tg: {
      nativeName: 'забо́ни тоҷикӣ́',
      englishName: 'Tajik',
    },
    'tg-TJ': {
      nativeName: 'тоҷикӣ',
      englishName: 'Tajik',
    },
    th: {
      nativeName: 'ภาษาไทย',
      englishName: 'Thai',
    },
    'th-TH': {
      nativeName: 'ภาษาไทย (ประเทศไทย)',
      englishName: 'Thai (Thailand)',
    },
    tl: {
      nativeName: 'Filipino',
      englishName: 'Filipino',
    },
    'tl-PH': {
      nativeName: 'Filipino',
      englishName: 'Filipino',
    },
    tlh: {
      nativeName: 'tlhIngan-Hol',
      englishName: 'Klingon',
    },
    tr: {
      nativeName: 'Türkçe',
      englishName: 'Turkish',
    },
    'tr-TR': {
      nativeName: 'Türkçe',
      englishName: 'Turkish',
    },
    'tt-RU': {
      nativeName: 'татарча',
      englishName: 'Tatar',
    },
    uk: {
      nativeName: 'Українська',
      englishName: 'Ukrainian',
    },
    'uk-UA': {
      nativeName: 'Українська',
      englishName: 'Ukrainian',
    },
    ur: {
      nativeName: 'اردو',
      englishName: 'Urdu',
    },
    'ur-PK': {
      nativeName: 'اردو',
      englishName: 'Urdu',
    },
    uz: {
      nativeName: "O'zbek",
      englishName: 'Uzbek',
    },
    'uz-UZ': {
      nativeName: "O'zbek",
      englishName: 'Uzbek',
    },
    vi: {
      nativeName: 'Tiếng Việt',
      englishName: 'Vietnamese',
    },
    'vi-VN': {
      nativeName: 'Tiếng Việt',
      englishName: 'Vietnamese',
    },
    'xh-ZA': {
      nativeName: 'isiXhosa',
      englishName: 'Xhosa',
    },
    yi: {
      nativeName: 'ייִדיש',
      englishName: 'Yiddish',
    },
    'yi-DE': {
      nativeName: 'ייִדיש (German)',
      englishName: 'Yiddish (German)',
    },
    zh: {
      nativeName: '中文',
      englishName: 'Chinese',
    },
    'zh-Hans': {
      nativeName: '中文简体',
      englishName: 'Chinese Simplified',
    },
    'zh-Hant': {
      nativeName: '中文繁體',
      englishName: 'Chinese Traditional',
    },
    'zh-CN': {
      nativeName: '中文（中国）',
      englishName: 'Chinese Simplified (China)',
    },
    'zh-HK': {
      nativeName: '中文（香港）',
      englishName: 'Chinese Traditional (Hong Kong)',
    },
    'zh-SG': {
      nativeName: '中文（新加坡）',
      englishName: 'Chinese Simplified (Singapore)',
    },
    'zh-TW': {
      nativeName: '中文（台灣）',
      englishName: 'Chinese Traditional (Taiwan)',
    },
    'zu-ZA': {
      nativeName: 'isiZulu',
      englishName: 'Zulu',
    },
  },
  {
    get: function (obj, lang) {
      if (lang.includes('-')) {
        lang = lang.split('-');
        lang = `${lang[0]}-${lang[1].toUpperCase()}`;
      } else if (lang.includes('_')) {
        lang = lang.split('_');
        lang = `${lang[0]}-${lang[1].toUpperCase()}`;
      }
      return obj[lang];
    },
  },
);

export default langmap;
